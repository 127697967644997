if (navigator.serviceWorker) {
  let port = location.port,
      themePath = document.documentElement.dataset.themepath,
      version = document.documentElement.dataset.version;;
  if (port == 80 || port == 443) {
    port = "";
  } else {
    port = `:${port}`
  }
  let localDocroot = `${location.protocol}//${location.hostname}${port}`;
  // Use the window load event to keep the page load performant
  window.addEventListener('load', function () {
    navigator.serviceWorker.register(`${localDocroot}${themePath}/service-worker.js`, {scope: `${themePath}/`}
    ).then(function (registration) {
      console.debug('[Workbox] Service worker registration successful, scope is:', registration.scope);
    }).catch(function (error) {
      console.log('[Workbox] Service worker registration failed, error:', error.stack);
    });
  });
}
